<template>
  <v-card>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong> Recipient Information</strong></span>
    </v-card-title> -->
    <v-card-title class="justify-space-between mb-0 py-7">
            <span class="text-h5"><strong>Recipient Information</strong></span>
        </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.tissue_id" label="Tissue ID" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.surgeon_name" label="Surgeon Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Surgery Location"
          v-model="filter.surgery_location"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Recipient Name" v-model="filter.recipient_name" outlined dense hide-details></v-text-field>
      </v-col>

      <v-col cols="12" md="6" >
        <v-btn color="primary" class="mr-2"  @click="recipientSearch()"> Search</v-btn>
        <v-btn color="primary"  @click="RecipientView()">Reset</v-btn>
        
      </v-col>
      <!-- <v-col cols="12" md="2">
      
      </v-col> -->
    </v-row>

    <v-data-table
      :headers="headers"
      :items="RecipientDetail"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
      item-key="donor_id"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template #[`item.date_of_transplant`]="{ item }">
        <pre>{{moment.utc(item.date_of_transplant).format("DD-MM-YYYY HH:mm")}}</pre>
      </template>
      
      <template #[`item.action`]="{ item }">
        <v-btn color="primary" v-if="item.pdf_path != ''" :href="`https://api.stage.ebsr.in/${item.pdf_path}`" target="_blank"> 
            Print 
          </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="pt-3 col-12">
          <v-col  sm="6" md="6">
            <p><strong> General information: </strong></p>
          <p>MR No: {{ item.medical_registration_no }}</p>
          <p>ID(Aadhar or PAN No.): {{ item.aadhar_pan_no }}</p>
          <p>Address: {{ item.address }}</p>
          </v-col>
          <v-col  sm="6" md="6">
            <p></p>
          <p >Age: {{ item.age }}</p>
          <p >Gender: {{ item.gender }}</p>
          <p >Email:  {{ item.email }}</p>
        </v-col>
        </v-row>
      </td>
    </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { api } from '@/config/config'
import donorService from '@/service/Donor.service'
import RecipientService from '@/service/Recipient.service'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      expanded:[],
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      RecipientDetail: [],
      permissionData: [],
      filter: {
        tissue_id: '',
        surgeon_name: '',
        surgery_location: '',
        recipient_name: '',
      },

      headers: [
        { text: 'Tissue ID', value: 'tissue_id', width: '150px' },
        { text: 'Surgeon Name', value: 'surgeon_name', width: '150px' },
        { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
        { text: 'Recipient Name', value: 'recipient_name', width: '150px' },
        { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
         { text: 'Action', value: 'action', width: '150px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.RecipientView()
      },
      deep: true,
    },
  },

  async mounted() {
    this.permission()
    this.RecipientView()
  },

  methods: {

    async permission() {
      const service = new donorService()
      let response = await service.donorList()
      this.permissionData = response.permissions[8]
    },

    async RecipientView() {
      this.listLoading = true
      const service = new RecipientService()
      let response = await service.RecipientDetail()
      if (response) {
        this.RecipientDetail = response.data
        // console.log("Hello Bill2",response.data)
        this.totallist = this.RecipientDetail.length
        ;(this.filter.tissue_id = ''),
          (this.filter.surgeon_name = ''),
          (this.filter.surgery_location = ''),
          (this.filter.recipient_name = '')
      } else {
        this.RecipientDetail = []
        this.totallist = 0
      }
    },

    async recipientSearch() {
      this.listLoading = true
      const data = {
        tissue_id: this.filter.tissue_id,
        surgeon_name: this.filter.surgeon_name,
        surgery_location: this.filter.surgery_location,
        recipient_name: this.filter.recipient_name,
      }

      if (this.filter.tissue_id == '' && this.filter.surgeon_name == '' && this.filter.surgery_location == '' && this.filter.recipient_name == '') {
        this.snackbarText = 'Kindly Enter The Keyword',
          this.snackbar = true
        return;
      }

      try {
        const response = await api.post(`recipientInformation/search`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.RecipientDetail = response.data.data
          this.totallist = this.RecipientDetail.length
        } else {
          this.RecipientDetail = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
